import { AxiosResponse } from 'axios';
import { Credentials } from '../../../hooks/useAuth';
import { createOauthApiInstance } from '../../api';
import { SignInResponse } from '../interfaces';

export const execute = async (credentials: Credentials): Promise<SignInResponse> => {
  const signInRespone: AxiosResponse<SignInResponse> = await createOauthApiInstance().post(
    '/token',
    credentials,
  );

  return signInRespone.data;
};
